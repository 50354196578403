import { Component, Inject } from '@angular/core';
import { addDoc, collection, doc, Firestore, updateDoc } from '@angular/fire/firestore';
import { Auth, authState } from '@angular/fire/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressWithId } from '../types';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['add-address.component.css'],
  standalone: false,
})
export class AddAddressComponent {
  user: string | undefined;
  addressForm: FormGroup;
  verb: string;
  export: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { address: AddressWithId },
    public dialogRef: MatDialogRef<AddAddressComponent>,
    public firestore: Firestore,
    public auth: Auth,
  ) {
    if (data !== null) {
      this.export = data.address.export;
      this.verb = 'Edit';
      this.addressForm = new FormGroup({
        familyname: new FormControl(data.address.familyname, Validators.required),
        address: new FormControl(data.address.address, Validators.required),
        city: new FormControl(data.address.city, Validators.required),
        state: new FormControl(data.address.state, Validators.required),
        zip: new FormControl(data.address.zip, Validators.required),
      });
    } else {
      this.export = true;
      this.verb = 'Add';
      this.addressForm = new FormGroup({
        familyname: new FormControl('', Validators.required),
        address: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        zip: new FormControl('', Validators.required),
      });
    }
    authState(this.auth).subscribe((user) => {
      this.user = user?.uid;
    });
  }

  onSubmit() {
    const address = {
      export: this.export,
      familyname: this.addressForm.value['familyname'],
      address: this.addressForm.value['address'],
      city: this.addressForm.value['city'],
      state: this.addressForm.value['state'],
      zip: this.addressForm.value['zip'],
      owner: this.user,
    };
    if (this.data !== null) {
      updateDoc(doc(this.firestore, 'addresses', this.data.address.id), address).then(() => {
        this.dialogRef.close();
      });
    } else {
      addDoc(collection(this.firestore, 'addresses'), address).then(() => {
        this.dialogRef.close();
      });
    }
  }
}
